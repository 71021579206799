@use '@angular/material' as mat;
@include mat.core();
// UNESP Theme overriding
$app-primary: mat.define-palette(mat.$blue-palette, 500);
$app-primary-gray: mat.define-palette(mat.$blue-palette, A100);
$app-secondary: mat.define-palette(mat.$pink-palette, 800);
$app-accent: mat.define-palette(mat.$light-green-palette, 500);
$app-warn: mat.define-palette(mat.$red-palette, 500);
$app-theme: mat.define-light-theme((
 color: (
   primary: $app-primary,
   secondary: $app-secondary,
   accent: $app-accent,
   warn: $app-warn,
 )
));
// DEFAULT
html, body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  color: white;
  background-color: #ededed;
}
body {
  margin: 0;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: #292a1d;
}
h1 {
  color: rgba(0, 0, 0, 0.54);
}
p {
  margin: 8px 0px;
}
a {
  text-decoration-color: mat.get-color-from-palette($app-primary);
  color: mat.get-color-from-palette($app-primary);
}
b {
  font-weight: 500;
}
ul {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
}
fieldset {
  border: none;
  border-radius: 4px;
  @extend .mat-elevation-z1;
}
fieldset legend {
  font-weight: 500;
  border-radius: 5px;
  background-color: #4297d7;
  color: #fff;
  padding: 5px 10px;
  @extend .mat-elevation-z2;
}
fieldset legend.basic {
  font-weight: 400;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  @extend .mat-elevation-z0;
}
fieldset.tipo-campo-titulo legend {
  background: transparent;
  color: black;
  box-shadow: none;
}
form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
table {
  width: 100%;
}
tr.mat-row, tr.mat-footer-row {
  height: 48px;
}
th {
  font-weight: bold;
}
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  padding: 0px 10px !important;
}
// CUSTOM
.top-div-left {
  background-color: #ff8b00;
}
.top-div-right {
  background-color: #b80cb3;
}
.bottom-div {
  background-color: #0c9cb8;
}
.customMatSelectPanel {
  min-width: 120px;
}
.page {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 0.5em;
  background-color: #fff;
  margin: 15px 20px;
  align-items: center;
  width: 100%;
}
.page-content {
  padding: 10px 15px 15px 15px;
}
.lista {
  margin: 0px 20px;
  width: 100%;
}
.action-button {
  margin: 20px 5px 5px 5px !important;
  @media screen and (max-width: 820px) {
    width: 100%;
  }
}
.full-width {
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
}
.col {
  flex: 1;
  margin-right: 20px;
}
.bordered {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  line-height: 30px;
}
.col:last-child {
  margin-right: 0;
}
.full-width-table {
  width: 100%;
}
.danger {
  color: #dc3545;
}
.red {
  color: red
}
.warn {
  background-color: #fff3cd !important;
  color: #664d03 !important;
  padding: 10px !important;
}
.warning-snackbar {
  background-color: #d1651e;
  color: #ffffff;
  margin-top: 70px !important;
  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}
.error-snackbar {
  background-color: #bd2323;
  color: #ffffff;
  margin-top: 70px !important;
  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}
.success-snackbar {
  background-color: #008b0e;
  color: #ffffff;
  margin-top: 70px !important;
  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}
.info-snackbar {
  background-color: #4e64e2;
  color: #ffffff;
  margin-top: 70px !important;
  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}
.table-container {
  width: 100%;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: mat.get-color-from-palette($app-primary);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: mat.get-color-from-palette($app-accent);
}
.card-warning {
  padding: 16px !important;
  color: #664d03 !important;
  background-color: #fff3cd !important;
  border-color: #ffecb5 !important;
}
.card-danger {
  padding: 16px !important;
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}
.card-warning p a {
  color: #664d03 !important;
  text-decoration: none !important;
}
.card-secondary {
  padding: 16px !important;
  color: #383d41 !important;
  background-color: #e2e3e5 !important;
  border-color: #d6d8db !important;
}
.info {
  padding: 16px !important;
  color: #664d03 !important;
  background-color: #fff3cd !important;
  border-color: #ffecb5 !important;
  margin-top: 10px !important;
}
.mb0 {
  margin-bottom: 0;
}
.nota-details {
  font-style: italic;
  font-size: .85rem;
  font-weight: normal;
}
// MATERIAL
.mat-mdc-table {
  .mat-mdc-header-row {
    background-color: #52a9f0;
  }
  .mat-mdc-header-cell {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
}
.mat-mdc-paginator-container, .mat-paginator-container {
  border-top: 1px solid #ccc !important;
  margin-bottom: 0px !important;
}
.mat-mdc-cell-header-container, .mat-cell-header-container {
  font-weight: bold;
}
.mat-mdc-row:nth-child(even), .mat-row:nth-child(even) {
  background-color: #f7f7f7 !important;
}
.mat-mdc-row:nth-child(odd), .mat-row:nth-child(odd) {
  background-color: #ded9d9 !important;
}
.mat-mdc-row:hover, .mat-row:hover {
  background-color: #92a8d1 !important;
}
.mat-sort-header-container {
  font-weight: bold;
}
.mat-mdc-row:nth-child(even) {
  background-color: #f7f7f7;
}
.mat-mdc-row:nth-child(odd) {
  background-color: #ded9d9b8;
}
.mat-mdc-row:hover {
  background-color: #0000ff29;
}
.mat-mdc-form-field {
  width: 100% !important;
  margin-top: 10px !important;
}
mat-form-field {
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0.1em;
  }
  .mat-mdc-form-field {
    &-underline {
      position: static;
    }
    &-subscript-wrapper {
      position: static;
    }
    &-hint-wrapper {
      position: initial;
    }
    &-bottom-align::before {
      position: absolute;
    }
  }
}
.mat-mdc-form-field-hint {
  color: #666666;
  margin-bottom: 10px;
}
.mat-column-action, .mat-column-action2 {
  text-align: right !important;
  justify-content: flex-end !important;
}
.mat-mdc-paginator-page-size-select {
  margin: 0 !important;
  width: 84px !important;
}
.mat-card > .mat-card-actions:last-child {
  padding: 0 0 10px 10px;
}
.mat-mdc-outlined-button > .mat-icon {
  margin-left: 0;
  margin-right: 0;
}
.mat-mdc-raised-button > .mat-icon {
  margin-left: 0;
  margin-right: 0;
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: black !important;
}
.mat-mdc-select-disabled .mat-mdc-select-value {
  color: black !important;
}
.mdc-text-field--disabled {
  background-color: rgba(127, 127, 127, 0.25) !important;
}
.mat-divider-horizontal {
  margin: 5px 0px 5px 0px !important;
}
.mat-icon {
  cursor: pointer;
}
.mat-mdc-header-cell {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #2196f3;
}
.mat-body p, .mat-body-1 p, .mat-typography .mat-body p, .mat-typography .mat-body-1 p, .mat-typography p {
  margin-bottom: 0;
}
.mat-mdc-menu-content {
  background-color: white;
}
.mat-toolbar-row .mat-icon-button {
  background-color: transparent;
  color: white;
}
.mat-mdc-dialog-container .mdc-dialog__content {
  color: #000 !important;
}
.mat-dialog-container {
  background-color: white;
}
.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
}
.mat-card, .mat-mdc-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  border-radius: 4px;
  background: white;
  color: #000000de;
}
.mat-button-toggle, .mat-card, .mat-mdc-card {
  font-family: Roboto,Helvetica Neue,sans-serif;
}
.mat-card-header .mat-card-title {
  margin-bottom: 12px;
  font-size: 20px;
}
.mat-card-header .mat-card-subtitle:not(:first-child) {
  margin-top: -8px;
}
.mat-card-title:not(:first-child), .mat-card-subtitle:not(:first-child) {
  margin-top: -4px;
}
.card-subtitle[_ngcontent-abx-c95] {
  padding-top: 10px;
}
.mat-card-actions, .mat-card-subtitle, .mat-card-content, .mat-mdc-card-actions, .mat-mdc-card-subtitle, .mat-mdc-card-content {
  display: block;
  margin-bottom: 16px;
}
.mdc-card__actions {
  padding: 0 20px 0 20px !important;
  margin-bottom: 0px !important;
  display: block !important;
  flex-direction: unset !important;
}
.mat-card-subtitle {
  color: #0000008a;
}
.mat-card-subtitle, .mat-card-content {
  font-size: 14px;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #2196f3;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
}
.mat-raised-button {
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  line-height: 36px;
}
.mdc-dialog__surface {
  padding: 20px;
}
.mdc-dialog__title, .mdc-dialog__content {
  padding: 0 !important;
}
.mat-snack-bar-container .mat-button {
  background: transparent;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.fases-provas {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  height: 30px;
  width: 200px;
  position: relative;
  background: transparent;
  cursor: pointer;
}

.fases-provas mat-select {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.fases-provas .mat-select-arrow {
  color: black !important;
}

unesp-core-header button {
  background-color: transparent;
  color: white;
}
// MOBILE
@media screen and (max-width: 960px) {
  tbody {
    width: 100%;
  }
  tr.mat-row, .mat-cell, tr.mat-mdc-row, .mat-mdc-cell {
    height: unset !important;
  }
  th.mat-mdc-header-cell, th.mat-header-cell,
  td.mat-mdc-cell, td.mat-cell,
  td.mat-mdc-footer-cell, td.mat-footer-cell {
    border-bottom: none !important;
  }
  .mat-mdc-table .mdc-data-table__row {
    height: unset !important;
  }
  .mat-elevation-z8 {
    background: transparent;
    box-shadow: none;
  }
  .mat-header-row, .mat-mdc-header-row {
    display: none;
  }
  $dont-show-colon-on-column: (
    ".mat-column-action",
    ".mat-column-action2"
  );
  .mat-table, .mat-mdc-table {
    background: transparent;
    * {
      box-sizing: border-box;
    }
    .mat-row, .mat-mdc-row {
      display: block;
      overflow: hidden;
      height: auto;
      position: relative;
      clear: both;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      background-color: #fff;
      border-radius: 3px;
      + .mat-row, + .mat-mdc-row {
        margin-top: 24px;
      }
    }
    .mat-row,
    .mat-footer-row,
    .mat-mdc-row,
    .mat-mdc-footer-row{
      min-height: 10px;
    }
    .mat-cell, .mat-mdc-cell {
      display: block;
      width: 100%;
      padding: 0 16px;
      margin: 16px 0;
      border: 0 none;
      background-color: transparent !important;
      overflow: visible;
      &:first-child {
        padding: 0 48px 0 16px;
      }
      &.m-card-sub-title {
        margin-top: -8px;
        padding: 0 48px 0 16px;
        color: rgba(0, 0, 0, 0.54);
      }
      &:not(#{$dont-show-colon-on-column})::before {
        content: attr(data-label) ":";
        float: left;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.54);
        margin-right: 5px;
        font-size: 0.85em;
      }
      .mat-mdc-raised-button > .mat-icon{
        padding-left: 0 !important;
      }
    }
    .has_label_on_mobile {
      &:before {
        content: attr(data-label) ":";
        display: inline;
        font-weight: normal;
      }
    }
    .mat-column-acoes {
      width: auto;
      padding: 8px 0 0 !important;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      &:before {
        display: none;
      }
    }
  }
  .mat-paginator, .mat-mdc-paginator {
    margin-top: 24px;
  }
  .mat-column-action, .mat-column-action2 {
    border-top: #6d7275 !important;
    border-top-style: solid !important;
    border-width: thin !important;
    text-align: center !important;
    margin: 0 !important;
  }
  .mat-stroked-button {
    margin-top: 5px !important;
  }
  .mat-mdc-paginator-range-actions, .mat-paginator-range-actions {
    display: unset !important;
  }
  .mat-mdc-paginator-range-label, .mat-paginator-range-label {
    text-align: right !important;
  }
  .mdc-card__actions {
    padding: 20px !important;
  }
}
@include mat.all-component-themes($app-theme);